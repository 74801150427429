import Vue from 'vue';
import Router from 'vue-router';

const routerOptions = [
  { path: '/', component: 'LoginForm', meta: { requiresAuth: false }  },
  { path: '/dashboard', component: 'DashBoard' },
  { path: '/device', redirect: '/device/list' },
  { path: '/device/list', component: 'Device/DeviceList' },
  { path: '/device/waste-types', component: 'Device/WasteTypeList' },
  { path: '/account', redirect: '/account/users'},
  { path: '/account/users', component: 'Account/UserList'},
  { path: '/account/admins', component: 'Account/AdminList'},
  { path: '/work', redirect: '/work/calendar'},
  { path: '/work/calendar', component: 'Work/WorkCalendar'},
  { path: '/work/list', component: 'Work/WorkList'},
  { path: '/monitor', redirect: '/monitor/list'},
  { path: '/monitor/list', component: 'Monitor/MonitorList'},
  { path: '/monitor/detail/:id', component: 'Monitor/MonitorDetail'},
  { path: '/usage', redirect: '/usage/detail'},
  { path: '/usage/detail', component: 'Usage/UsageDetail'},
  { path: '/notice', redirect: '/notice/list'},
  { path: '/notice/list', component: 'Notice/NoticeList'},
  { path: '*', component: 'NotFound' },
];

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`../components/${route.component}.vue`),
  };
});

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes,
});