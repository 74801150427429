<template>
  <v-app>
    <v-toolbar
      prominent
      max-height="128"
      elevation="0"
      bottom flat outlined
      v-show="this.$route.path!='/'">
      <!--<v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>-->
      <v-toolbar-title>
        <v-img v-if="$i18n.locale=='en'" src="@/assets/logo_top.b290796a_eng.png" max-width="350" max-height="110"></v-img>
        <v-img v-else src="@/assets/logo_top.b290796a.png" max-width="350" max-height="110"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-card class="d-flex align-end elevation-0" height="110">
        <v-card-text>
          <v-btn
            v-for="item in menuItems"
            v-show="item.enabled"
            :key="item.title"
            :to="item.path"
            @click="logout(item.action)"
            rounded text>
            <v-icon left dark>{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-btn>
        </v-card-text>
      </v-card>
      <div style="display:scroll;position:fixed;left:95%;top:10px;">
        <v-row no-gutters>
          <v-col>
            <v-img src="@/assets/ko.png" max-width="30" @click="changeLocale('ko')"></v-img>
          </v-col>
          <v-col>
            <v-img src="@/assets/en.png" max-width="30" @click="changeLocale('en')"></v-img>
          </v-col>
        </v-row>
      </div>
    </v-toolbar>

    <v-main>
      <router-view @login="login"></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    sidebar: false,
    menuItems: [
      { title: '대시보드', path: '/dashboard', icon: 'mdi-view-dashboard', enabled: true },
      { title: '저울 등록/관리', path: '/device', icon: 'mdi-scale', enabled: true },
      { title: '계정등록관리', path: '/account', icon: 'mdi-account-multiple', enabled: true },
      { title: '일정관리', path: '/work', icon: 'mdi-calendar', enabled: true },
      { title: '모니터링', path: '/monitor', icon: 'mdi-monitor', enabled: true },
      { title: '공지사항', path: '/notice', icon: 'mdi-bulletin-board', enabled: true },
    ],
    user: {},
  }),
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  },
  watch: {
    locale() {
      this.initMenu();
    }
  },
  mounted() {
    this.login();
    this.initMenu();
  },
  methods: {
    initMenu() {
      this.menuItems[0].title = this.$t('대시보드.메뉴');
      this.menuItems[1].title = this.$t('저울등록관리.메뉴');
      this.menuItems[2].title = this.$t('계정등록관리.메뉴');
      this.menuItems[3].title = this.$t('일정관리.메뉴');
      this.menuItems[4].title = this.$t('모니터링.메뉴');
      this.menuItems[5].title = this.$t('공지사항.메뉴');
      if (this.menuItems.length == 7) {
        this.menuItems[6].title = this.user.id + this.$t('앱.로그아웃');
      }
    },
    changeLocale(locale) {
      localStorage.setItem('locale', locale);
      this.$i18n.locale = locale;
      this.initMenu();
    },
    async login() {
      var user = JSON.parse(localStorage.getItem('user'));
      this.user = user;
      if (user) {
        this.menuItems.forEach(d => {
          if (d.action == 'logout') {
            this.menuItems.pop();
          }
        });
        this.menuItems.push({
          title: user.id + this.$t('앱.로그아웃'),
          action: 'logout',
          icon: 'mdi-account',
          enabled: true,
        });
        this.menuItems[1].enabled = user.view_scale;
        this.menuItems[2].enabled = user.view_account;
        this.menuItems[3].enabled = user.view_work;
        this.menuItems[4].enabled = user.view_monitor;
        //this.menuItems[5].enabled = user.view_usage;
        this.menuItems[5].enabled = user.view_notice;
      }
      else {
        this.user = {};
      }
    },
    async logout(action) {
      if (action != 'logout') return;
      this.menuItems.pop();
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      this.$router.push('/');
    },
  },
};
</script>

<style>
@font-face {
  font-family: 'SCDream4';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.v-application { font-family: SCDream4 !important; }
table th:first-child { border-top:1px solid #dddddd; }
table th + th { border-left:1px solid #dddddd; border-top:1px solid #dddddd; }
table th + td { border-left:1px solid #dddddd; border-bottom:1px solid #dddddd; }
table tr:first-child th + td { border-top:1px solid #dddddd; }
table tr th + td + th { border-top:1px solid #dddddd; }
th { background-color: #eeeeee; }
table td:first-child { border-bottom:1px solid #dddddd; }
table td + td { border-left:1px solid #dddddd; border-bottom:1px solid #dddddd; }
a { text-decoration: none; }
</style>
